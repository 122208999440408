@import '../../styles/imports';
.alexa-page {
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  @media screen and (min-width: 600px) {
    min-height: 100vh;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: min-content auto auto;
    grid-row-gap: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: auto 950px auto;
  }

  @media screen and (max-width: 320px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xs.png);
  }

  @media screen and (min-width: 321px) and (max-width: 480px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-sm.png);
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-md.png);
  }

  @media screen and (min-width: 769px) and (max-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-lg.png);
  }

  @media screen and (min-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xl.png);
  }

  .alexa-site {
    grid-column: 2/2;
    grid-row: 2/2;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background: transparent;
    padding: 0 var(--space-small-size) 0 var(--space-small-size);
  }

  #title_wrapper {
    justify-self: center;
  }

  #message_wrapper {
    margin-top: 0.5rem;
  }

  .greeting {
    color: var(--light-grey);
    font-family: var(--font-regular);
    font-size: 26px;
    font-weight: normal;
    text-align: center;
    margin: 0;
  }

  #paragraph_heading {
    font-style: normal;
    font-weight: var(--font-weight-600);
    font-size: 40px;
    line-height: 24px;
    margin: 0;

    @media screen and (min-width: 320px) and (max-width: 479px) {
      font-size: 30px;
    }

    @media screen and (max-width: 767px) {
      line-height: 44px;
    }
  }

  #list_heading {
    font-style: normal;
    font-weight: var(--font-weight-600);
    font-size: var(--font-big-size);
    line-height: 28px;

    margin-top: var(--space-medium-size);
    margin-bottom: var(--space-medium-size);

    @media screen and (max-width: 320px) {
      font-size: var(--font-medium-size);
    }
  }

  #confirm_account_access_message ul {
    font-style: normal;
    font-weight: var(--font-weight-400);
    font-size: var(--font-big-size);
    padding-left: var(--space-large-size);
    margin: 0;
    line-height: var(--space-large-size);

    @media screen and (max-width: 320px) {
      font-size: var(--font-medium-size);
    }
  }

  .main-alexa {
    font-family: var(--font-regular);
    color: var(--dark-grey);
    min-height: auto;
    grid-column: 1/7;
    padding: 0 var(--space-medium-size) 0 var(--space-medium-size);
  }

  .bold {
    font-weight: bold;
  }

  #btn_wrapper {
    padding-top: 1.25rem;

    & > div {
      text-align: center;
    }

    .btn-alexa {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      padding: 5px 35px;
      border-radius: 8px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .btn-alexa-primary {
      width: 264px;
      height: 64px;
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--white);
      background-color: var(--mid-blue);
      border-color: var(--mid-blue);

      &:hover {
        background-color: var(--mid-blue);
        border-color: var(--mid-blue);
      }
    }

    #cancel_btn_alexa {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .hidden {
    display: none;
  }

  .bottom_space {
    margin-bottom: var(--space-medium-size);
  }
}
