#change_email_form {
  position: relative;
  width: 100%;
  margin-top: 0.25rem;
}

#input_wrapper {
  width: 70%;
  min-width: 50px;
  padding-bottom: 1rem;
}

#change_email_input {
  border: 1px solid var(--light-grey);
  color: var(--light-grey);
  border-radius: 4px;
  font-family: var(--font-regular);
  font-size: 0.875rem;
  font-weight: normal;

  padding: 0.375rem 0.5rem;

  width: 100%;

  &:disabled {
    cursor: pointer;
    pointer-events: none;
    background-color: transparent;
    border-color: var(--disabled);
    color: var(--disabled);
  }
}

#change_email_client_error_message {
  color: var(--error);
}

#change_email_input.invalid {
  border: 1px solid var(--error);

  background-image: url('../svgs/info.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem);
  background-size: 1.25rem 1.25rem;
}

#change_email_input.valid {
  border: 1px solid var(--success);

  background-image: url('../svgs/tick.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem);
  background-size: 1.25rem 1.25rem;
}

#change_email_server_error_message {
  color: var(--white);
  margin: auto 0;
  padding: 0.625rem 1.25rem;
}

#change_email_server_error_retry_button {
  color: var(--white);
  font-family: var(--font-regular);
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin: 0;
  padding-right: 1.25rem;
  padding-left: 0.75rem;
}

#change_email_server_error_wrapper:not(.hidden) {
  background: var(--snackbar);
  border-radius: 4px;
  position: absolute;
  width: 100%;
}

#change_email_btn {
  margin-top: 0.625rem;
}

.error_vl {
  border-left: 1px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -0.75rem;
}
