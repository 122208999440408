:root {
--white: #ffffff;
--black: #000000;
--black2: #0f0f0d;
--disabled: #bebfbf;
--light-grey: #191a1a;
--dark-grey: #0f0f0d;
--grey: #eeeeee;
--grey2: #9d9d9d;
--grey3: #ebebeb;
--grey-dark: #3b4444;
--light-blue: #ebf5ff;
--blue: #0072ee;
--mid-blue: #3358CC;
--dark-blue: #0654a7;
--hoverblue: #0050dd;
--aliceblue: #edeff0;
--error: #d60000;
--success: #008a00;
--snackbar: #3b3b3b;

--radius: 2px;

// Fonts sizing
--font-small: 10px;
--font-normal: 13px;
--font-medium-size: 16px;
--font-big-size: 18px;

//Spacing
--space-small-size: 8px;
--space-medium-size: 16px;
--space-large-size: 24px;

//Font weight
--font-weight-600: 600;
--font-weight-400: 400;

}

// For security reason fonts are only allowed on localhost with dev assets.
$font-path: '#{$assets-path}/fonts/registration/';
$font-path-dev: '#{$assets-path-dev}/fonts/registration/';
