p span {
  font-weight: bold;
  text-decoration: none;
  color: var(--black);
  font-family: var(--font-bold);
}

label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  color: var(--black);
  font-size: 0.8125rem;
  font-family: var(--font-regular);
}
