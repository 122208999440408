.alexa-header {
  grid-column: 1/4;
  grid-row: 1/2;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    width: 239px;
    height: 162px;

    @media screen and (max-width: 319px) {
      width: 160px;
      height: 109px;
    }
  }
}
