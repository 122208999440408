#loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #000000cc;
}

#loader {
  width: 430px;
  height: 188px;
  padding: 1em;
  margin: 0 auto 1em;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 7px;
  background-color: #aab6c4;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
}

#loader svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

#loader svg path,
#loader svg rect {
  fill: #032548;
}
