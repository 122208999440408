@import '../../styles/imports';

.linking-result-page {
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  @media screen and (max-width: 320px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xs.png);
  }

  @media screen and (min-width: 321px) and (max-width: 480px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-sm.png);
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-md.png);
  }

  @media screen and (min-width: 769px) and (max-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-lg.png);
  }

  @media screen and (min-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xl.png);
  }

  .site-linking-result {
    grid-column: 2/2;
    grid-row: 2/2;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background: transparent;
    padding: 0 var(--space-small-size) 0 var(--space-small-size);
  
    @media screen and (max-width: 600px) {
      margin-top: 2.5rem;
      margin-bottom: 1.25rem;
    }
  }

  .main-linking-result {
    font-family: var(--font-regular);
    padding: 24px 16px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    grid-column: 1/7;

    #error_wrapper {
      display: flex;
      justify-content: center;

      #error_title {
        font-size: 30px;
        line-height: 34px;
        margin: 80px 0 0 0;

        @media only screen and (min-width: 320px) and (max-width: 478px) {
          margin: 0;
        }
      }
    }

    #success_title {
      font-size: 36px;
      line-height: 39px;
      margin: 12px 0;
    }

    .success_subtitle {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    }
  }

  #btn_wrapper {
    position: fixed;
    bottom: 24px;
    left: 0;
    width: 100%;

    .btn-alexa {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      padding: 5px 35px;
      border-radius: 8px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .btn-alexa-primary {
      width: 264px;
      height: 64px;
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--white);
      background-color: var(--mid-blue);
      border-color: var(--mid-blue);

      &:hover {
        background-color: var(mid-blue);
        border-color: var(mid-blue);
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 478px) {
      bottom: 108px;
    }

    @media only screen and (min-width: 480px) and (max-width: 767px) {
      bottom: 108px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      bottom: 252px;
    }

    @media only screen and (min-width: 1024px) {
      margin-top: 48px;
      position: initial;
    }
  }

  .hidden {
    display: none !important;
  }
}
