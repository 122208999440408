.generic-error {
  .generic-error-main {
    min-height: auto;
    grid-column: 1/7;
    display: grid;
    grid-template-rows: 2rem 3.75rem 6.3rem 6.875rem 3.3rem min-content;
  }
  .btn-generic {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 5px 35px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: var(--font-regular);
  }
  
  .btn-generic-primary {
    color: var(--white);
    background-color: var(--blue);
    border-color: var(--blue);
    font-size: 1rem;
    padding: 0.6875rem 2.063rem;
    font-weight: normal;
  
    &:hover {
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
    }
  }
}