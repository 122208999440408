.text {
  margin-top: 40px;
  width: 403px;

  @media (min-width: 480px) {
    margin-top: 60px !important;
    margin-bottom: 40px !important;
  }

  @media (min-width: 768px) {
    margin-left: 100px;
  }

  @media (min-width: 1024px) {
    margin-left: 270px;
  }

  @media (min-width: 1440px) {
    margin-left: 50px;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 20px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 44px !important;
    font-family: 'Roboto-Regular', sans-serif;
    color: #111111;
    letter-spacing: 1px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
      letter-spacing: unset;
    }
    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 44px;
      padding-right: 180px;
    }
    @media (min-width: 1440px) {
      line-height: 44px;
      padding-right: 0px;
    }
  }

  li {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;

    @media (min-width: 768px) {
      padding-right: 80px;
    }

    @media (min-width: 1024px) {
      padding-right: 270px;
    }

    @media (min-width: 1440px) {
      padding-right: 100px;
    }
  }

  p {
    color: #111111;
    margin-top: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
}
