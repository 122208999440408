.title_wrapper {
  justify-self: center;
}

.message_wrapper {
  margin-top: 0.5rem;
  justify-self: center;
}

.greeting {
  color: var(--light-grey);
  font-family: var(--font-regular);
  font-size: 26px;
  font-weight: normal;
  margin: 0;
}

.broadcasting .greeting {
  color: #0a0a0a;
}

.paragraph {
  color: var(--dark-grey);
  font-family: var(--font-regular);
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 0 1.25rem;
}

.broadcasting .paragraph {
  color: #0a0a0a;
}

#btn_wrapper {
  padding-top: 1.25rem;

  & > div {
    text-align: center;
  }
}

.hidden {
  display: none;
}