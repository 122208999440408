@font-face {
  font-family: 'Roboto-Bold';
  src: url('#{$font-path}Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('#{$font-path}Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('#{$font-path}Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold-Dev';
  src: url('#{$font-path-dev}Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular-Dev';
  src: url('#{$font-path-dev}Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium-Dev';
  src: url('#{$font-path-dev}Roboto-Medium.ttf') format('truetype');
}
