@import '../../styles/imports';

.hidden {
  display: none;
}

.email-verification-app {
  .ev-header {
    grid-column: 1/7;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .banner {
    align-items: center;
    border-bottom-color: white;
    background-position: 50%;
    background-image: url(#{$assets-path}/images/broadcasting/sign-in-form-header.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column-reverse;
    height: 130px;
    overflow: visible;
    padding: 0;
    width: 100%;

    @media screen and (min-width: 480px) {
      height: 146px;
      background-size: cover;
      background-position: unset;
    }
  }

  ul {
    padding-left: 25px;
  }

  .close_button_wrapper {
    position: absolute;
    top: 25px;
    right: 25px;

    .close_button {
      color: #ffffff;
      width: 51px;
      height: 51px;
      text-align: center;
      background-color: transparent;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: 15px;
      color: #0a0a0a;
    }
  }

  .hidden {
    display: none;
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 5px 35px;
    border-radius: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Roboto-Regular', sans-serif;

    &:disabled {
      opacity: 0.6;
      cursor: pointer;
      pointer-events: none;
      background-color: transparent;
      border-color: var(--disabled);
      color: var(--disabled);
    }
  }

  .btn-border {
    color: var(--blue);
    background-color: transparent;
    font-size: 0.875rem;
    border-color: var(--blue);
    padding: 0.8rem 0.8rem;
  }

  .btn-secondary-error {
    color: var(--light-grey);
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: right;
    padding-right: 0;
    padding-top: 2px;
    outline: none;
    box-shadow: none;
    border-radius: 8px;
  }

  #change_email_server_error_retry_button {
    color: var(--white);
    font-family: 'Roboto-Regular', sans-serif;
    font-weight: 400;
    text-align: left;
    padding: 0;
    margin: 0;
    padding-right: 1.25rem;
    padding-left: 0.75rem;
    border-left-color: white;
    border-radius: 0px !important;
  }

  .btn-secondary {
    align-items: center;
    color: var(--light-grey);
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: right;
    padding: 5px 20px !important;
    outline: none;
    box-shadow: none;
    border: 0;
  }

  .btn-secondary-cancel-email {
    display: block !important;
    margin-top: 10px;
    color: var(--light-grey);
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: right;
    outline: none;
    box-shadow: none;
    border: 0;
    padding: 0 12px;
  }

  .btn-secondary-email {
    display: block !important;
    margin-top: 5px;
    color: var(--light-grey);
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: right;
    padding-right: 0;
    padding-top: 0px;
    outline: none;
    box-shadow: none;
    border: 0;
    padding: 0 10px;
  }

  .btn-link {
    color: var(--blue);
    font-size: 0.875rem;
    font-weight: normal;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: var(--dark-blue);
    }
  }

  .btn-primary {
    background: var(--mid-blue);
    border-radius: 8px;
    width: 150px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--white);

    &:hover {
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
    }
  }

  .btn-border {
    color: var(--white);
    background-color: var(--mid-blue);
    border-color: var(--mid-blue);
    border-radius: 8px;
  }

  #message_wrapper {
    margin-top: 0.7rem;
  }
  #grace_period {
    background-color: #ecf1ff;
    color: var(--mid-blue);
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .paragraph {
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-top: 15px;
  }

  .greeting {
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 44px;
    color: #111111;
  }

  .grace-period {
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  label {
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  button.btn {
    font-family: 'Roboto-Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .background {
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: black !important;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    @media (max-width: 768px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-sm.png);
    }
    @media (max-width: 1024px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-md.png);
    }
    @media (max-width: 1440px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-lg.png);
    }
    @media (min-width: 1440px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-xl.png);
    }
  }

  button.close_button {
    background-color: transparent;
    font-size: 15px;
    color: #0a0a0a;
  }

  .contentWrapper {
    z-index: -1;
    max-width: 1920px; // max width of grid on virgin radio site
    display: flex !important;
    justify-content: center !important;
    padding-right: 100px;
    padding-left: 100px;

    margin: auto;

    .text {
      padding-top: 100px;
      padding-left: 90px;
      margin-top: 40px;
      width: 403px;
      text-align: left;
      color: #111111;
      font-family: 'Roboto-Regular', sans-serif;

      ul {
        list-style-type: disc;
        padding-inline-start: 20px;
      }

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        font-family: 'Roboto-Regular', sans-serif;
        color: #111111;
        letter-spacing: 1px;
        margin-bottom: 40px;
        @media (min-width: 768px) {
          font-size: 32px;
          line-height: 36px;
          letter-spacing: unset;
        }
        @media (min-width: 1024px) {
          font-size: 40px;
          line-height: 44px;
        }
        @media (min-width: 1440px) {
          margin-bottom: 40px;
        }
      }

      li {
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
      }

      p {
        color: #111111;
        margin-top: 32px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .form-section {
      display: flex;
      justify-content: flex-end;
      padding-top: 100px;
      padding-bottom: 10px;

      svg {
        height: 20px;
        width: 25px;
      }

      .thanks_message_email_address {
        font-family: 'Roboto-Regular', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      #change_email_input {
        font-family: 'Roboto-Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      #change_email_btn {
        margin-top: 0;
        margin-bottom: 0.625rem;
      }

      .section_title {
        font-family: 'Roboto-Regular', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #111111;
      }

      .form-wrapper {
        width: 382px;

        .header {
          &__image {
            width: 100% !important;
            background-image: url(#{$assets-path}/images/broadcasting/sign-in-form-header.png);
            background-color: #fff;
            height: 142px;
          }
        }

        .section {
          padding-top: 0.5rem;
        }
      }
    }

    .section {
      box-shadow: unset;
    }

    .nav {
      background-color: white;
    }

    #form {
      padding-bottom: 1rem;
    }
  }

  @media screen and (min-width: 1440px) {
      .text {
        text-align: left;
        order: 1;
        margin-top: 50px;
        padding-top: 0;
        width: 650px;
        margin-left: 0;

        h1 {
          padding-right: 0;
        }

        li {
          padding-right: 0;
        }
      }
  }

  @media screen and (min-width: 1025px) and (max-width: 1439px) {
    position: relative;

    .contentWrapper {
      flex-direction: column;
      align-items: center;

      .form-section {
        order: 2;
        padding-top: 40px !important;
      }

      .text {
        text-align: left;
        order: 1;
        margin-top: 80px;
        padding-top: 0;
        padding-left: 0;
        width: 650px;
        margin-left: 220px;

        h1 {
          padding-right: 120px;
        }

        li {
          padding-right: 220px;
        }
      }

      .grace_period_wrapper {
        margin-top: 1.5rem;
      }
    }
  }


  @media screen and (max-width: 1024px) {
    position: relative;

    .background {
      background-position: 20%;
    }

    .contentWrapper {
      flex-direction: column;
      align-items: center;

      .form-section {
        order: 2;
        padding-top: 40px !important;
      }

      .text {
        text-align: left;
        order: 1;
        margin-top: 80px;
        padding-top: 0;
        padding-left: 0;
        width: 500px;
      }

      .grace_period_wrapper {
        margin-top: 1.5rem;
      }
    }
  }

  // MD

  @media screen and (max-width: 900px) {
    .close_button_wrapper {
      display: block;
      margin: 0px;
    }

    position: relative;

    .background {
      background-position: 20%;
    }

    .contentWrapper {
      flex-direction: column;
      align-items: center;

      .form-section {
        order: 2;
        padding-top: 40px !important;
      }

      .text {
        text-align: left;
        order: 1;
        margin-top: 80px;
        padding-top: 0;
        padding-left: 0;
        width: 500px;
      }

      .grace_period_wrapper {
        margin-top: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 670px) {
    .close_button_wrapper {
      display: block;
      margin: 0px;
    }

    position: relative;

    .background {
      background-position: 20%;
    }

    .contentWrapper {
      flex-direction: column;
      align-items: center;

      .form-section {
        order: 2;
        padding-top: 40px !important;
      }

      .text {
        text-align: left;
        order: 1;
        margin-top: 50px;
        padding-top: 0;
        width: 400px;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        font-family: 'Roboto-Regular', sans-serif;
        color: #111111;
        letter-spacing: 1px;
      }

      .grace_period_wrapper {
        margin-top: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .close_button_wrapper {
      display: none;
    }

    .text {
      display: none !important;
    }

    .background {
      display: none !important;
    }
    .contentWrapper {
      .form-section {
        padding-top: 0 !important;
        width: 100vw;
        .form-wrapper {
          img {
            height: auto;
          }
          width: 100%;
        }
      }
      padding: 15px;

      .form-section {
        padding: 0;
      }
    }
  }
}

.section {
  background-color: var(--white);
  grid-column: 2/2;
  grid-row: 2/2;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: 600px) {
    padding-top: 2.5rem;
    margin-bottom: 1.25rem;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 1.25rem;
  }
}

.email-verification-main {
  min-height: auto;
  grid-column: 1/7;
  display: grid;
  grid-template-rows: auto auto auto auto auto min-content;
}

.main_emailSent {
  min-height: auto;
  grid-column: 1/7;
  display: grid;
  grid-template-rows: auto auto auto auto auto min-content;
}

.main_hardStop {
  min-height: auto;
  grid-column: 1/7;
  display: grid;
  grid-template-rows: auto auto auto auto auto min-content;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.flex-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 8px;

  @media screen and (max-width: 480px) {
    padding: 0px;
  }
}

.flex-resendEmail {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 480px) {
    display: flex;
    justify-content: space-between;
  }
}

.main_title_wrapper {
  justify-self: center;
}

.main_message_wrapper {
  margin-top: 0.5rem;
}

.greeting {
  color: var(--light-grey);
  font-family: var(--font-regular);
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
}

.paragraph {
  color: var(--dark-grey);
  font-family: var(--font-regular);
  font-size: 0.8125rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 1.125rem;
  margin: 0 1.25rem;
}

.grace_period_wrapper {
  margin-top: 1.5rem;
}

#grace_period,
#grace_period_outside {
  background-color: var(--light-blue);
  color: var(--blue);
  padding: 0.625rem;
  border-radius: 0.625rem;
  margin: 0 1.25rem;
  font-family: var(--font-regular);
  font-size: 0.8125rem;
  letter-spacing: 0px;
  line-height: 1.125rem;
}

.main_btn_wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;

  & > div {
    text-align: center;
  }
}

.section_title {
  color: var(--light-grey);
  font-family: var(--font-semi-bold);
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
}

.resend_wrapper {
  margin: 0 1.25rem;
}

.resend_wrapper_hardStop {
  margin: 1.5rem 1.25rem;
}

#change_email_wrapper {
  margin: 0 1.25rem;
}

#change_email_wrapper_hardStop {
  margin: 0 1.25rem;
}

#edit_email_btn {
  color: var(--blue);
}

@media screen and (max-width: 479px) {
  .flex {
    display: flex;
    justify-content: space-between;
  }

  .resend_wrapper {
    margin: 1rem 1.25rem;
  }

  #change_email_wrapper {
    margin: 3rem 1.25rem;
  }

  .main_message_wrapper {
    margin-top: 0.5rem;
  }

  .grace_period_wrapper {
    margin-top: 2rem !important;
  }

  .main_btn_wrapper {
    margin-top: 2rem;

    & > div {
      text-align: center;
    }
  }
}
