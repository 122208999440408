.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 5px 35px;
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: var(--font-regular);


  &:disabled {
    opacity: 0.6;
    cursor: pointer;
    pointer-events: none;
    background-color: transparent;
    border-color: var(--disabled);
    color: var(--disabled);
  }
}

.btn-primary {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
  
  &:hover {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
  }
}

.app.broadcasting .btn {
  border: 8px solid transparent;
}

.app.broadcasting .btn-primary {
  background-color: var(--mid-blue);
  border-color: var(--mid-blue);

  &:hover {
    background-color: #254cac;
    border-color: #254cac;
  }
}

#cancel_btn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}