@import '../../styles/imports';
.app.broadcasting {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 320px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xs.png);
  }
  @media screen and (min-width: 321px) and (max-width: 480px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-sm.png);
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-md.png);
  }
  @media screen and (min-width: 769px) and (max-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-lg.png);
  }
  @media screen and (min-width: 1440px) {
    background-image: url(#{$assets-path}/images/alexa/registration/alexa-background-xl.png);
  }
  .error-main {
    grid-column: 1/7;
  }
  .msg_wrapper {
    justify-self: center;
    @media screen and (min-width: 480px) and (max-width: 767px) {
      padding: 0 90px;
    }
  }
  .message {
    color: #0a0a0a;
    font-family: var(--font-regular);
    font-size: 26px;
    font-weight: var(--font-weight-500);
    line-height: 44px;
    text-align: center;
  }
  .site.broadcasting {
    background: transparent;
  }
  .broadcasting.header {
    background-color: var(--white);
    border-bottom: 1px solid #111111;
    .header__image {
      width: 100%;
      height: auto;
      @media screen and (min-width: 481px) {
        width: auto;
        height: 227px;
      }
    }
  }
}
