@import '../../styles/imports';
//Variables specific to profile
$font-small: 14px;
$font-normal: 16px;
$font-medium-size: 18px;
$font-large: 24px;

$line-height: 1.5em;

$link: var(--grey-dark);
$link-active: var(--blue);

$radius: 2px;

.hidden {
  display: none;
}

.app-profile {
  font-family: 'Roboto-Regular', sans-serif;
  @media (min-width: 480px) {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    background-position: top;
    background-image: url(#{$assets-path}/images/broadcasting/background-sm.png);
  }
  @media (min-width: 768px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-md.png);
  }
  @media (min-width: 1024px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-lg.png);
  }
  @media (min-width: 1440px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-xl.png);
  }

  .close_button_wrapper {
    position: absolute;
    top: 15px;
    right: 15px;

    .close_button {
      color: var(--black);
      text-align: center;
      background-color: transparent;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: 15px;
    }
    :hover {
      background-color: transparent;
    }
  }

  .main {
    display: block;
    min-height: unset;
  }

  .header {
    grid-column: 1/7;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    border-bottom: 2px solid var(--white);
  }

  .banner {
    align-items: center;
    border-bottom-color: white;
    background-position: 50%;
    background-image: url(#{$assets-path}/images/broadcasting/sign-in-form-header.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column-reverse;
    height: 130px;
    overflow: visible;
    padding: 0;
    width: 100%;

    @media screen and (min-width: 480px) {
      height: 146px;
      background-size: cover;
      background-position: unset;
    }
  }

  .nav {
    background-color: white;
    text-align: center;
    flex: 1;
    display: flex;

    &__item {
      flex: 1 1 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-family: var(--font-bold);
      font-size: 1rem;
      padding: 1.5rem;
      color: rgba(156, 163, 170, 0.3);

      &:visited {
        color: var(--black2);
      }

      &--active {
        color: var(--black2);
        box-shadow: inset 0px -7px 0px 0px var(--aliceblue);
      }
    }
  }

  .nav__item.nav__item--active {
    font-family: 'Roboto-Regular', sans-serif;
    box-shadow: inset 0 -2px 0 0 #111111;
    padding: 20px 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
    display: block;
  }

  .nav__item {
    display: none;
  }

  p.select__info-p {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    margin: 0 !important;
  }

  button.close_button {
    background-color: transparent;
    font-size: 15px;
    color: #0a0a0a;
  }

  .form {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: min-content 3rem;
    display: grid;
  }

  .fieldset {
    border: 0;
    grid-column: 1/7;
    margin: 0;
    min-width: 0;
    padding: 0 1rem 0 1.25rem;

    &__info {
      text-align: left;
      font-size: 0.9375rem;
      font-family: var(--font-semi-bold);
      color: var(--black);
      margin-bottom: 1.25rem;
    }

    sup {
      padding-right: 2px;
    }
  }

  .contentWrapper {
    z-index: -1;
    max-width: 1920px;
    display: flex !important;
    justify-content: center !important;
    padding-right: 100px;
    padding-left: 100px;
    margin: auto;
    flex-direction: column;
    align-items: center;

    .form-section {
      order: 2;
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;

      .header {
        &__image {
          width: 100% !important;
          height: auto;
        }
      }
      .form-wrapper {
        width: 376px;
      }

      .fieldset {
        padding: 0px 15px;
        @media (min-width: 480px) {
          padding: 0px 20px;
        }

        &__info {
          font-family: 'Roboto-Regular', sans-serif;
          color: #111111;
        }
      }

      .select {
        border-radius: 3px;
        border: 1px solid #919191;

        &__label {
          font-family: 'Roboto-Regular', sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #111111;
          margin-bottom: 10px !important;
        }

        &__info {
          padding: 8px;
          bottom: 32px;
          margin-bottom: 0px;

          &::before,
          &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-bottom: 3px solid var(--black);
            top: -4px;
            right: 15px;
          }

          .select__button-info {
            background-color: #12387a;
          }
        }

        &__button-info {
          right: 3px;
          width: 31px;
          height: 31px;
          top: 1px;
          transition: opacity 0.4s ease-in-out;
          background-color: #dadada;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .select__block--active .select__button-info {
      background-color: #12387a;

      svg {
        path,
        circle {
          fill: white;
        }
      }
    }

    option,
    select {
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 14px;
    }

    select.select {
      font-family: 'Roboto-Regular', sans-serif;
    }

    button.submit {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      background-color: #3358cc !important;
      border-radius: 8px;
      color: #ffffff;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      line-height: 24px;
      margin: 0px auto 26px;
      padding: 0;
      text-align: center;
      text-transform: capitalize;
      width: calc(100% - 30px);

      @media (min-width: 480px) {
        width: calc(100% - 40px);
      }
    }

    .text {
      margin-top: 40px;
      width: 403px;

      ul {
        list-style-type: disc;
        padding-inline-start: 20px;
      }

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        font-family: 'Roboto-Regular', sans-serif;
        color: #111111;
        letter-spacing: 1px;
        margin-bottom: 40px;
        @media (min-width: 768px) {
          font-size: 32px;
          line-height: 36px;
          letter-spacing: unset;
        }
        @media (min-width: 1024px) {
          margin-bottom: 62px;
          font-size: 40px;
          line-height: 44px;
        }
        @media (min-width: 1440px) {
          margin-bottom: 40px;
        }
      }

      li {
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
      }

      p {
        color: #111111;
        margin-top: 32px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .profile-site {
      display: grid;
      background: var(--white);
      grid-column: 2/2;
      grid-row: 2/2;
      box-shadow: unset;
      grid-template-rows: min-content auto;
      grid-template-columns: repeat(6, 1fr);
    }

    #form {
      padding-bottom: 40px;
    }
  }

  .info-message {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

  .title-message {
    text-align: left;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 5px;
  }

  .title {
    font-family: 'Roboto-Regular', sans-serif;
    padding: 10px 20px 25px 20px;
  }

  .hidden {
    display: none !important;
  }

  a {
    color: $link;

    &:active,
    &:focus,
    &:visited {
      color: $link-active;
    }
  }

  .submit {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: var(--blue);
    border-radius: 2px;
    border: none;
    color: var(--white);
    font-family: var(--font-regular);
    font-size: $font-normal;
    grid-column: 1/7;
    margin: 0 1rem;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.4s ease;
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:active {
      background-color: var(--hoverblue);
    }

    &[disabled] {
      color: var(--white);
      background-color: var(--grey3);
    }
  }

  // SM

  @media screen and (min-width: 480px) {
    .close_button_wrapper {
      display: block;
    }

    .contentWrapper {
      .text {
        display: block;
      }
    }
  }

  // MD

  @media screen and (min-width: 768px) {
    .contentWrapper {
      .text {
        margin-top: 100px;
        width: 520px;
      }
    }
  }

  // LG
  @media screen and (min-width: 1024px) {
    .contentWrapper {
      .text {
        width: 690px;
      }
    }
  }

  //XL
  @media screen and (min-width: 1440px) {
    .contentWrapper {
      flex-direction: row;
      gap: 120px;
      padding-top: 150px;
      align-items: unset;

      .form-section {
        order: 1;
        margin-top: 0px;
      }

      .text {
        order: 2;
        margin-top: 40px;
        width: 448px;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .close_button_wrapper {
      display: none;
    }

    .contentWrapper {
      .text {
        display: none;
      }

      .form-section {
        margin-top: 0;

        .form-wrapper {
          width: 100vw;
        }
      }
      padding: 0;

      .form-section {
        padding: 0;
      }
    }
  }
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: $radius;
  border: 1px solid var(--grey);
  box-shadow: none;
  color: var(--grey2);
  flex: 1;
  font-family: var(--font-regular);
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  transition: border-color 0.4s ease;

  &:active,
  &:focus:valid,
  &:focus,
  &:visited {
    border: 1px solid var(--black);
    color: var(--blacktrue);
  }

  &.touched.error,
  &.touched:invalid {
    border: 1px solid var(--error);
  }

  &:valid {
    color: var(--blacktrue);
  }

  &[disabled] {
    color: var(--black2);
    background: var(--grey3);
  }

  &__label {
    display: inline-flex;
    color: var(--black2);
    margin-bottom: 0.625rem;
    font-size: 1rem;
    font-family: var(--font-semi-bold);
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__info {
    display: none;
    font-size: 12px;
    color: var(--black);
    padding: 1rem;
    border: 1px solid var(--black);
    border-radius: 5px;
    position: relative;
    background: var(--white);
    margin-bottom: 1rem;
    bottom: 1rem;
    animation: fadeIn 0.4s ease both;

    &::before,
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.9rem 1.2rem 0.9rem;
      border-color: transparent transparent var(--black) transparent;
      position: absolute;
      top: -1.15rem;
      right: 0.3rem;
    }

    &::after {
      border-color: transparent transparent var(--white) transparent;
      top: -1.05rem;
    }

    .select__block--active & {
      display: flex;
      flex-direction: column;
    }
  }

  &__button-info {
    position: relative;
    right: 0.2rem;
    width: 1.9rem;
    height: 1.9rem;
    border: none;
    top: 0.3rem;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    margin-left: 1rem;
    transition: opacity 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    .wrap {
      width: 50%;
      height: 0;
      padding-top: 48%;
      position: relative;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      text-indent: -99999rem;
      display: inline-flex;
      position: absolute;
    }
  }
}

.select__wrap {
  margin-bottom: 20px !important;
  display: flex;
  position: relative;

  &::before,
  &::after {
    background: var(--black);
    border-radius: $radius;
    content: '';
    height: 0.12em;
    position: absolute;
    right: 3.9em;
    top: 0.7em;
    transform-origin: 100%;
    transform: rotate(-45deg);
    width: 0.85em;
  }

  &::after {
    transform-origin: 0%;
    transform: rotate(45deg);
    right: 4.2em;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
