.header {
  grid-column: 1/7;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  @media screen and (min-width: 480px) {
    grid-column: 1/4;
    grid-row: 1/2;
    align-items: center;
    background-color: var(--black);
    display: flex;
    justify-content: center;
  }

  &__image {
    height: 142px;

    @media screen and (min-width: 480px) {
      width: 600px;
      height: auto;
    }
  }
}

.broadcasting.header {
  background-color: var(--white);
  border-bottom: 1px solid #111111;
}

.broadcasting.header .header__image {
  width: 100%;
  height: auto;

  @media screen and (min-width: 480px) {
    width: auto;
    height: 227px;
  }
}

.broadcasting.header {
  background-color: var(--white);
  border-bottom: 1px solid #111111;
}

.broadcasting.header .header__image {
  width: 100%;
  height: auto;

  @media screen and (min-width: 480px) {
    width: auto;
    height: 227px;
  }
}