:root {
  --font-regular: 'Roboto-Regular';
  --font-bold: 'Roboto-Bold';
  --font-semi-bold: 'Roboto-Medium';
}

html {
  box-sizing: border-box;
  font-size: var(--font-medium-size);

  @media screen and (min-width: 480px) {
    font-size: var(--font-big-size);
  }
}

body {
  margin: 0;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

svg {
  shape-rendering: geometricPrecision;
}

img {
  image-rendering: crisp-edges;
}

// Animation
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

a {
  color: var(--dark-grey);
}
