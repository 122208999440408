@import '../../styles/imports';
.app.broadcasting {
    min-height: 100vh;

    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 320px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-sm.png);
    }

    @media screen and (min-width: 321px) and (max-width: 768px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-md.png);
    }

    @media screen and (min-width: 769px) and (max-width: 1440px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-lg.png);
    }

    @media screen and (min-width: 1440px) {
      background-image: url(#{$assets-path}/images/broadcasting/background-xl.png);
    }

    .landing-page-header {
        background-color: var(--white);
        border-bottom: 1px solid #111111;

        .img {
          width: 100% !important;
          height: auto;

          @media screen and (min-width: 481px) {
            width: auto;
            height: 227px;
          }
        }
      }

      .title_wrapper {
  justify-self: center;
}

.message_wrapper {
  margin-top: 0.5rem;
  justify-self: center;
}

.greeting {
  color: var(--light-grey);
  font-family: var(--font-regular);
  font-size: 26px;
  font-weight: normal;
  margin: 0;
}

.broadcasting .greeting {
  color: #0a0a0a;
}

.paragraph {
  color: var(--dark-grey);
  font-family: var(--font-regular);
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 0 1.25rem;
}

.broadcasting .paragraph {
  color: #0a0a0a;
}

#btn_wrapper {
  padding-top: 1.25rem;

  & > div {
    text-align: center;
  }
}

.hidden {
  display: none;
}

  }

  .site.broadcasting {
    background: transparent;
  }

  .broadcasting.header {
    background-color: var(--white);
    border-bottom: 1px solid #111111;

    .header__image {
      width: 100%;
      height: auto;

      @media screen and (min-width: 481px) {
        width: auto;
        height: 227px;
      }
    }
  }

  .btn-error {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 5px 35px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: var(--font-regular);
  }
  
  .btn-error-primary {
    color: var(--white);
    background-color: var(--blue);
    border-color: var(--blue);
    font-size: 1rem;
    padding: 0.6875rem 2.063rem;
    font-weight: normal;
  
    &:hover {
      background-color: var(--dark-blue);
      border-color: var(--dark-blue);
    }
  }