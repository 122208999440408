.app {
  @media screen and (min-width: 600px) {
    min-height: 100vh;
    display: grid;
    grid-template-columns: auto 600px auto;
    grid-template-rows: min-content auto auto;
    grid-row-gap: 2.5rem;
  }
}

.app.broadcasting {
  min-height: 100vh;

  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 320px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-sm.png);
  }

  @media screen and (min-width: 321px) and (max-width: 768px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-md.png);
  }

  @media screen and (min-width: 769px) and (max-width: 1440px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-lg.png);
  }

  @media screen and (min-width: 1440px) {
    background-image: url(#{$assets-path}/images/broadcasting/background-xl.png);
  }
}

.site {
  background: var(--white);
  grid-column: 2/2;
  grid-row: 2/2;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: 600px) {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
}

.site.broadcasting {
  background: transparent;
}

.main {
  min-height: auto;
  grid-column: 1/7;
  display: grid;
  grid-template-rows: 2rem 3.75rem 6.3rem 6.875rem 3.3rem min-content;
}
